/* colores
#f2a7bb
#78bf8a */


.box-usuario .img-usuario1{

    width: 200px;
    height: 240px;
    background: #fff;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
   
}

.bd-usuario{
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background: #fff;
   
}

.centrarusr{
    text-align: center;
}

.box-usuario{

   position: relative;
   width: 400px;
   height: 500px;
   display: flex;
   justify-content: center;
   align-items: center;
   background: #fff;
}

.box-usuario{

    text-align: center;
}

.box-usuario::before{

    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: #fff;
    z-index: -1;

}

.box-usuario::after{

    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: #fff;
    z-index: -2;
    filter: blur(40px);

}

.box-usuario::before,
.box-usuario::after{

    background: linear-gradient(235deg,#f2a7bb,#000,#78bf8a);
}


.box-usuario h1{

    color: #f2a7bb;
}

