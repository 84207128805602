@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;700&display=swap');

:root{

    --rosa: #f2a7bb;
    --verde:#78bf8a;
    --gradient: linear-gradient(90deg, var(--rosa), var(--verde))
}


*{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    border: none;
    
}



/* inicia seccion home */
.seccion-sobremi{

min-height: 100vh;
padding: 0 9%;
padding-top: 7.5rem;
padding-bottom: 3rem;

}

.home-sobremi{

    display: flex;
    align-items: center;
    background: url(../assets/img/pina.png) no-repeat;
    background-size: cover;
    background-position: center;
}

.home-sobremi .contenido-sobremi{

    padding-left: 1rem;
}

.home-sobremi .contenido-sobremi .hola{

    font-size: 2rem;
    color: #444;
}


.home-sobremi .contenido-sobremi .nombre-sobremi{

    font-size: 5.5rem;
    color: #fff;
    font-weight: bolder;
    text-transform: uppercase;
}

.home-sobremi .contenido-sobremi .nombre-sobremi span{

   color: var(--verde);
   font-weight: bolder;
    text-transform: uppercase;
    
}

.home-sobremi .contenido-sobremi p{

    max-width: 50rem;
    font-size: 1.5rem;
    color: #666;
    padding-bottom: 1rem;
}

/* acerca sobre mi */


.acerca-sobremi .hilera-sobremi{

    display: flex;
    flex-wrap: wrap;

}

.acerca-sobremi .hilera-sobremi .titulo-sobremi{

    font-size: 3rem;
    color: var(--rosa);
    padding: .5rem 0;
}

.acerca-sobremi .hilera-sobremi .barra-progreso{

    flex: 1 1 40rem;
    padding: 1rem;
}

.acerca-sobremi .hilera-sobremi .barra-progreso .progreso{

    padding: 1rem 0;
}

.acerca-sobremi .hilera-sobremi .barra-progreso .progreso h3{

  display: flex;
  justify-content: space-between;
  color: #666;
  font-size: 2rem;
}

.acerca-sobremi .hilera-sobremi .barra-progreso .progreso .barra-mi{
    height: 1rem;
    width: 100%;
    border: .2rem solid var(--rosa);
    border-radius: 5rem;
    margin: .5rem 0;
}

.acerca-sobremi .hilera-sobremi .barra-progreso .progreso .barra-mi span{

    display: block;
    background: var(--rosa);
    border-radius: 5rem;
    height: 100%;
}

.acerca-sobremi .hilera-sobremi .barra-progreso .progreso:nth-child(2) .barra-mi span{

    width: 100%;
}

.acerca-sobremi .hilera-sobremi .barra-progreso .progreso:nth-child(3) .barra-mi span{

    width: 85%;
}

.acerca-sobremi .hilera-sobremi .barra-progreso .progreso:nth-child(4) .barra-mi span{

    width: 60%;
}




.acerca-sobremi .hilera-sobremi .info-personal{

    flex: 1 1 55rem;
    padding: 1rem;
}

.acerca-sobremi .hilera-sobremi .info-personal .box-container-sobremi{

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.acerca-sobremi .hilera-sobremi .info-personal .box-container-sobremi .box-sobremi{

    padding: 1rem;
}

.acerca-sobremi .hilera-sobremi .info-personal .box-container-sobremi .box-sobremi h3{

   font-size: 2rem;
   color: #333;
   padding: 1rem 0;
}

.acerca-sobremi .hilera-sobremi .info-personal .box-container-sobremi .box-sobremi h3 span{

    color: var(--verde);
    font-size: 1.7rem;
}

.acerca-sobremi .contador{

    display: flex;
    flex-wrap: wrap;
}

.acerca-sobremi .contador .box-sobremi{
    margin: 1rem;
    padding: 2rem;
    border-radius: .5rem;
    border: .1rem solid rgba(0, 0, 0, .2);
    flex: 1 1 20rem;
}

.acerca-sobremi .contador .box-sobremi h3{

    font-size: 4rem;
    color: var(--rosa);
}

.acerca-sobremi .contador .box-sobremi p{

    font-size: 2rem;
    color: #666;
    padding: .5rem 0;
}

/* SERVICIOS INICIA AQUI */

.servicio {
    background: #f9f9f9;
}

.servicio .box-container-sobremi{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

}

.servicio .box-container-sobremi .box-sobremi{

    border: .1rem solid rgba(0, 0, 0, .2);
    border-radius: .5rem;
    box-shadow: 0 .5rem  1rem rgba(0, 0, 0, .1);
    margin: 1.5rem;
    padding: 2.5rem;
    background: linear-gradient(79deg, rgba(255, 71, 87, .2) 50%, #fff 50.1%);
    position: relative;
    z-index: 0;
    flex: 1 1 30rem;
}

.servicio .box-container-sobremi .box-sobremi .numero-servicio{

    font-size: 7rem;
    color: rgba(0, 0, 0, .05);
    position: absolute;
    top: .1rem;
    right: 1rem;
    z-index: -1;
    font-weight: bolder;
}

.servicio .box-container-sobremi .box-sobremi:hover .numero-servicio{

    font-size: 9rem;
}

.servicio .box-container-sobremi .box-sobremi h3{

    font-size: 2.4rem;
    color: #333;
}

.servicio .box-container-sobremi .box-sobremi p{

    font-size: 1.5rem;
    color: #666;
    padding: 1rem 0;
}


/* EDUCACION Y EXPERIENCIA */

.educacion .hilera-sobremi{

     display: flex;
     flex-wrap: wrap;
}

.educacion .hilera-sobremi .columna-experiencia{

    flex: 1 1 40rem;
    margin: 1rem;
    padding: 2rem;
    border: 1rem solid rgba(0, 0, 0, .2);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    border-radius: .5rem;
}

.educacion .hilera-sobremi .columna-experiencia .titulo-sobremi{

    color: var(--verde);
    font-size: 2.5rem;
    padding-bottom: 2rem;

}

.educacion .hilera-sobremi .columna-experiencia .box-sobremi{

    padding-left: 2.5rem;
    padding-bottom: 2rem;
    border-left: .2rem solid var(--rosa);
    position: relative;
}

.educacion .hilera-sobremi .columna-experiencia .box-sobremi::before{

    content: "";
    position: absolute;
    top: .1rem;
    left: -1.1rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: var(--rosa);
}

.educacion .hilera-sobremi .columna-experiencia .box-sobremi .año{

    font-size: 1.5rem;
    color: #999;
    padding-bottom: .5rem;
}

.educacion .hilera-sobremi .columna-experiencia .box-sobremi .año i{

    padding-right: .5rem;
}

.educacion .hilera-sobremi .columna-experiencia .box-sobremi h3{

    color: #333;
    font-size: 2rem;
}

.educacion .hilera-sobremi .columna-experiencia .box-sobremi p{

    color: #666;
    font-size: 1.5rem;
    padding-top: .5rem;
}

/* PORTAFOLIO INICIO */


.portafolio{

    background: #f9f9f9;

}

.portafolio .box-container-sobremi{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.portafolio .box-container-sobremi .box-sobremi{

    margin: 1rem;
    height: 25rem;
    flex: 1 1 30rem;
    border-radius: .5rem;
    border: .5rem solid rgba(0, 0, 0, .2);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    overflow: hidden;
    position: relative;
}

.portafolio .box-container-sobremi .box-sobremi img{

    height: 100%;
    width: 100%;
    object-fit: cover;
}

.portafolio .box-container-sobremi .box-sobremi .info-porta{

    position: absolute;
    top: -100%;
    left: 0;
    background: var(--gradient);
    color: #fff;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-size: 3.5rem;
}

.portafolio .box-container-sobremi .box-sobremi:hover .info-porta{

    top: 0;

}

.portafolio .box-container-sobremi .box-sobremi:hover img{

    transform: scale(1.2);
}

/* media query */

@media (max-width:991px){

    .seccion-sobremi{

        padding: 0 3%;
        padding-top: 7.5rem;
        padding-bottom: 3rem;
        
        }
}


@media (max-width:768px){

    .home-sobremi{

        background-position: left;
    }

    .home-sobremi .contenido-sobremi .nombre-sobremi{

        font-size: 3.5rem;
    }
}


@media (max-width:450px){

    .encabezado{
        font-size: 4.5rem;
    }
}