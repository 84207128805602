@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;700&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* inicia seccion home */
.seccion-gale{

    min-height: 100vh;
    padding: 0% 9%;
    padding-top: 7.5rem;
    padding-bottom: 3rem;
    
    }
    
    .home-gale{
    
        display: flex;
        align-items: center;
        background: url(../assets/img/wea-doble.png) no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .home-gale .contenido-gale{
    
        padding-left: 1rem;
    }
    
    .home-gale .contenido-gale .hola-gale{
    
        font-size: 2rem;
        color: #444;
    }
    
    
    .home-gale .contenido-gale .nombre-gale{
    
        font-size: 5.5rem;
        color: #f2a7bb;
        font-weight: bolder;
        text-transform: uppercase;
    }
    
    .home-gale .contenido-gale .nombre-gale span{
    
       color: var(--verde);
       font-weight: bolder;
        text-transform: uppercase;
        
    }
    
    .home-gale .contenido-gale p{
    
        max-width: 50rem;
        font-size: 1.5rem;
        color: #000;
        padding-bottom: 1rem;
    }


    /* *********************  aqui va la GALERIA*/

    .contenedor-gale{

        position: relative;
        min-height: 100vh;
        background: #F7F7F7;
    }

    .contenedor-gale .contenedor-imagenes{

        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        justify-content: center;
        padding: 10px;
    }

    .contenedor-gale .contenedor-imagenes .gale{

        height: 250px;
        width: 350px;
        border: 3px solid #f2a7bb;
        box-shadow: 0 5px 15px rgba(0, 0, 0, .1);
        overflow: hidden;
        cursor: pointer;
    }

    .contenedor-gale .contenedor-imagenes .gale img{

        height: 100%;
        width: 100%;
        object-fit: cover;
        transition: .2s linear;
    }

    .contenedor-gale .contenedor-imagenes .gale:hover img{

        transform: scale(1.1);
    }

    .contenedor-gale .pop-imagen{

        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, .9);
        height: 100%;
        width: 100%;
        z-index: 100;
        display: none;
    }

    .contenedor-gale .pop-imagen span{

        position: absolute;
        top: 0px;
        right: 10px;
        font-size: 60px;
        font-weight: bolder;
        color: #fff;
        cursor: pointer;
        z-index: 100;
    }

    .contenedor-gale .pop-imagen img{

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 5px solid #fff;
        border-radius: 5px;
        width: 550px;
        object-fit: cover;
    }


    /* media query */

@media (max-width:991px){

    .seccion-gale{

        padding: 5% 3%;
        padding-top: 7.5rem;
        padding-bottom: 3rem;
        
        }
}


@media (max-width:768px){

    .home-gale{

        background-position: left;
    }

    .home-gale .contenido-gale .nombre-gale{

        font-size: 3.5rem;
    }

    .contenedor-gale .pop-imagen img{

        width: 95%;
    }
}


@media (max-width:450px){

    .encabezado{
        font-size: 4.5rem;
    }
}