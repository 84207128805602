/* colores
#f2a7bb
#78bf8a */


*{
    margin: 0;
    padding:0;
    font-family: sans-serif;
}       

.her2{
    height: 100%;
    width: 100%;
    background-image: linear-gradient(rgba(0,0,0,0.4), 
    rgba(0,0,0,0.4)), url(../assets/img/plan.png);
    background-position: center;
    background-size: cover;
    position: absolute;
    text-align: center;
}

.form-box2{
    width: 380px;
    height: 480px;
    position: relative;
    margin: 6% auto;
    background:#fff;
    padding: 5px;
    overflow: hidden;
}

.input-group2{
    top: 180px;
    position: absolute;
    width: 280px;
    align-items: center;
    transition: .5s;
    margin-left: 11%;
    font-size: 15px;
    
}

.input-field2{
    width: 100%;
    padding: 10px 0;
    margin: 20px 0;
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid #999;
    outline: none;
    background: transparent;
    
}

.submit-btn2{
    width: 85%;
    padding: 10px 30px;
    cursor: pointer;
    display: block;
    margin: auto;
    background: linear-gradient(to right,#f2a7bb, #78bf8a);
    border: 0;
    outline: none;
    border-radius: 30px;
}

