/* colores
#f2a7bb
#78bf8a */


*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.navegacion-sesion{

    position: relative;
    top: 1;
    left: 0;
    width: 100%;
    height: 100%;
    background: #f2a7bb;
    transition: 0.2s;
    display: flex;
}

.navegacion-sesion .navArea{

    position: relative;
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.navegacion-sesion .navArea ul li{

    list-style: none;
}

.navegacion-sesion .navArea ul li::before{

    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #78bf8a;
    z-index: -1;
    opacity: 0;
    transition: 0.5s;
}

.navegacion-sesion .navArea ul li:nth-child(1):before{

    background: linear-gradient(to right, #B1E693, #FFC6C7);
}

.navegacion-sesion .navArea ul li:nth-child(2):before{

    background: linear-gradient(to left, #6ECB63, #F3C1C6);
}

.navegacion-sesion .navArea ul li:nth-child(3):before{

    background: linear-gradient(230deg, #BBEAA6, #F35588);
}

.navegacion-sesion .navArea ul li:hover::before{

    opacity: 1;
}

.navegacion-sesion .navArea ul li a{ 

    font-size: 3em;
    font-weight: 600;
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);
}

.navegacion-sesion .navArea ul li:hover a{

    color: rgba(255, 255, 255, 1);
}

.imgArea{
    position: relative;
    width: 50%;
    height: 120vh;
    background: #78bf8a;
}

.imgArea img{

    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px){

    .navegacion-sesion .navArea{

        position: absolute;
        width: 100%;
        height: 100rem;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
    }

    .navegacion-sesion .navArea ul{

        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .navegacion-sesion .navArea ul li{

        background: #FF3796;
        color: #fff;
        margin: 5px 10px;
        padding: 5px 10px;
    }

    .navegacion-sesion .navArea ul li:hover{

        background: #D2F3E0;
    }

    .navegacion-sesion .navArea ul li a{

        color: #000;
        font-size: 2em;
        letter-spacing: 2px;
    }

    .imgArea{
        position: absolute;
        width: 100%;
        height: 100vh;
        background: #78bf8a;
    }

    .navegacion-sesion .navArea ul li:hover::before{

        opacity: 0;
    }

    .imgArea{
        position: absolute;
        width: 100%;
        height: 100vh;
        background: #78bf8a;
        opacity: 0.7;
    }
}