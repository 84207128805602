/* colores
#f2a7bb
#78bf8a */


  
  .inicios{

    margin-right: 720;

  }





.car{
  height: 850px;
  width: 100%;
}
.carousel{
  position:relative;
  text-align: center;
  background-image: linear-gradient(350deg, #f2a7bb, #78bf8a);
  height: 100%;
  width: 100%;
}

.carousel-inner img{
  height: 400;
  width: 700;
}

*{
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}


/* menu y presentacion */
.header{
  height: 100vh;
  background: #fff;
}

.navbar-style{

  box-shadow: 0 5px 10px #efefef;
  text-transform: uppercase;
  
}



.logo{
  height: 48px;
  padding: 2px 10px;
}

.icon-bar{
  background: #f2a7bb;
  
}

li a{
  color: #333;
}

.texto-grande{

  font-size: 52px;
  margin: 20px 0;
  color: #78bf8a;
}

a.btn{
  margin: 30px 10px;
  width: 150px;
  padding: 10px;
  border-radius: 20px;
}

a.btn-first{
  background-color:#f2a7bb;
  color: #fff;
}

a.btn-second{
  background: transparent;
  border: 1px solid #78bf8a;
  color: #333;
}

a.btn:hover{
  background: #78bf8a;
  border: none;
  color: #fff;
  box-shadow: 5px 5px 10px #999;
  transition: 0.3s;

}

.banner-info, .banner-imagen{
  margin: 30px 0;
}

.navbar-nav li a:hover{
  color: #78bf8a;
  background-color: #f2a7bb;
}

.imagen-inicio{
  /* width: 300px;
  height: 300px; */
  background-color: #fff;
  margin-left: 100px;
  margin-bottom: 150px;
}

/* iconos descriptivos */

#features{
  position: relative;
  text-align: center;
  margin: 10px;
 
}

.titulo-iconos{

  font-size: 1.5rem;
}

.feature-box{
  padding: 4.5%;
}

.icon{
  margin-bottom: 1rem;
  font-size: 100px;
  padding-bottom: 10;
  color: #f2a7bb;

}

.icon:hover {
  background-color: #78bf8a;
}


.iconos-descriptivos img{

  height: 50px;
  
}



/* acerca */
.acerca{
  min-height: 100vh;
  width: 100%;
  background: #f2a7bb;
  color: #fff;
  position: relative;
}

.izquierda-sidebar{
  width: 20%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #78bf8a;
}

.row{
  padding: 0 10%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: relative;
  margin-top: 5%;
}

.columna-izquierda{
  flex-basis: 40%;
}

.columna-derecha{
  flex-basis: 55%;
}

.columna-izquierda img{
  width: 80%;
  cursor: pointer;
  margin-top: 86px;
  filter: grayscale(0);
  transition: filter 1s;
}

.columna-izquierda img:hover{
  
  filter: grayscale(1);
  
}

.columna-derecha h1{
  font-size: 55px;
  font-weight: 600;
}

.columna-derecha p{
  color: #777;
  margin: 20px 0 70px;
}

.social-media{
  display: flex;
  margin-top: 30px;
}

.icono{
  width: 100px;
  height: 100px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
  border-radius: 6px;
  overflow: hidden;
  transition: transform 0.5s;
}

.icono img{
  width: 28px;
}

.icono a{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #78bf8a;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.5s;
}

.icono a:hover{
  opacity: 1;
}

.icono:hover{
 transform: translateY(-5px);
}


/* testimonios */

.heroe{
  width: 100%;
  height: 100vh;
  background: #fff;
  /* background: #f6fbff; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reseña{
  font-size: 30px;
  position: relative;
  margin-bottom: 80px;
}

.reseña::after{
  content: '';
  width: 150px;
  height: 3px;
  background: linear-gradient(to right, #78bf8a, #f2a7bb);
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
}

.review-box{
  width: 90%;
  max-width: 700px;
  height: 300px;
  border-radius: 10px;
  box-shadow: -10px 10px 40px rgba(0,0,0,0.25);
  position: relative;
  overflow: hidden;
}

.card{
  height: 300px;
  padding: 40px;
  color: #777;
  line-height: 22px;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  z-index: 1;
}

.perfil{
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.perfil img{
  width: 70px;
  border-radius: 50px;
  margin-right: 20px;
}

.perfil h3{
  font-size: 26px;
  color: #78bf8a;
  margin-bottom: 8px;
}

#slide{
  width: 100%;
  padding-right: 60px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s;
}

.sidebar{
  width: 60px;
  height: 100%;
  padding: 15px 0;
  box-sizing: border-box;
  background: #f2a7bb;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sidebar img{
  width: 25px;
  padding: 5px;
  background: #fff;
  border-radius: 50%;
  cursor: pointer;

}

.card::before{
  content: '';
  width: 110px;
  height: 110px;
  border-bottom-right-radius: 100%;
  background: #f2a7bb;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

::selection{
  background: #f2a7bb;
  color: #000;
}

.buton-galeria {
  background-color: #78bf8a; 
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
}

.button2:hover {
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24),0 17px 50px 0 rgba(0,0,0,0.19);
}


/* seccion contacto */

.contacto{
  background-color: #78bf8a;
  font-size: 14px;
}

.container-contacto{
    /* background-color: #78bf8a; */
    
    width: 80%;
    margin: 50px auto;
}

.contacto-box{
    background: #fff;
    display: flex;
}

.contacto-izquierda{
    flex-basis: 60%;
    padding: 40px 60px;

}

.contacto-derecha{
  flex-basis: 40%;
  padding: 40px;
  background: #f2a7bb;
  color: #fff;
  
}

.contacto h1{
margin-top: 100px;
}

.container-contacto h1{
  margin-bottom: 10px;
}

.container-contacto p{
  margin-bottom: 40px;
}

.input-row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.input-row .grupo-entrada{
  flex-basis: 45%;
}

input{
  width: 100%;
  border: none;
  border-bottom: 1px solid #f2a7bb;
  outline: none;
  padding-bottom: 5px;
}

textarea{
  width: 100%;
  border: 1px solid #f2a7bb;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
}

label{
  margin-bottom: 6px;
  display: block;
  color: #78bf8a;
}

.boton-contacto-form{
  background: #f2a7bb;
  width: 100px;
  border: none;
  outline: none;
  color: #fff;
  height: 30px;
  border-radius: 30px;
  margin-top: 20px;
  box-shadow: 0 5px 15px 0 rgba(28, 0, 181,0.3);
}

.contacto-izquierda h3{
  color: #f2a7bb;
  font-weight: 600;
  margin-bottom: 30px;
}

.contacto-derecha h3{
  
  font-weight: 600;
  margin-bottom: 30px;
}

tr td:first-child{
  padding-right: 20px;
}

tr td{
  padding-top: 20px;
}




.hilera{
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.col{
  flex-basis: 25%;
  padding: 10px;
}

.col:nth-child(2), col:nth-child(3){
  flex-basis: 15%;
}


.col h3{
  width: fit-content;
  margin-bottom: 40px;
  position: relative;
}

.email.id{
  width: fit-content;
  border-bottom: 1px solid #f2a7bb;
  margin: 20px 0;
}

ul li{
  list-style: none;
  margin-bottom: 12px;
}

ul li a{
  text-decoration: none;
  /* color: #fff; */
}



hr{
  width: 90%;
  border: 0;
  border-bottom: 1px solid #ccc;
  margin: 20px auto;
}

.copyright{
  text-align: center;
}

@media(max-width: 700px){
  footer{
      bottom: unset;
  }
  .col{
      flex-basis: 100%;
      
  }
  
  .col:nth-child(2), col:nth-child(3){
      flex-basis: 100%;
  }
}
