@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;700&display=swap');

:root{

    --rosa: #f2a7bb;
    --verde:#78bf8a;
    --gradient: linear-gradient(90deg, var(--rosa), var(--verde))
}


*{
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    border: none;
    

}

/* navbar  e inicio*/

*::selection{

    background: var(--rosa);
    color: #fff;
}
 
html{
    font-size: 62.5%;
    overflow-x: hidden;
}

body{
    background: #f9f9f9;
}

section{

    min-height: 100vh;
    padding: 0 9%;
    padding-top: 7.5rem;
}

header{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    padding: 2rem 9%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
}

header .logo-inicio{

    font-size: 2rem;
    color: var(--rosa);

}

header .logo-inicio span{

    color: var(--verde);
}

header .navbar-inicio a{

    font-size: 1.7rem;
    margin-left: 2rem;
    color: var(--rosa);
}

header .navbar-inicio a:hover{

    color: var(--verde);
}

header input{

    display: none;
}

header label{

    font-size: 3rem;
    color: var(--verde);
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
}

/* inicio cotenido */

.home{
    display: flex;
    align-items: center;
    justify-content: center;
    /*background: url(imagenes-inicio/dieta.png) no-repeat;*/
    background-size: cover;
    background-position: center;
}

.home .imagen-home{

    width: 40vw;
    animation: flotar 3s linear infinite;
    margin-right: 40px;
    margin-top: 70px;

}

@keyframes flotar{

    0%, 100%{

        transform: translateY(0rem);
    }

    50%{

        transform: translateY(-3.5rem);
    }
}

a:hover {
    color:#fff; 
    text-decoration:none; 
    cursor:pointer;  
}

.home .contenido-home h3{

    font-size: 5.5rem;
    color: #333;
    text-transform: uppercase;
}

.home .contenido-home h3 span{

    color: var(--rosa);
    text-transform: uppercase;
}

.home .contenido-home p{

    font-size: 1.7rem;
    color: #666;
    padding: 1rem 0;
}

.btn-home{

    display: inline-block;
    margin-top: 1rem;
    padding: .8rem 3rem;
    border-radius: 5rem;
    background: var(--gradient);
    font-size: 1.7rem;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    transition: all .3s linear;
}

.btn-home:hover{
    transform: scale(1.1);
}

.encabezado{
    text-align: center;
    background: var(--gradient);
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    font-size: 3.5rem;
    text-transform: uppercase;
    padding: 1rem;
}

.contenido-caracteristicas .box-container-caracterisiticas{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.contenido-caracteristicas .box-container-caracterisiticas .box-caracteristicas{

    flex: 1 1 30rem;
    background: #fff;
    border-radius: .5rem;
    border: .1rem solid rgba(0, 0, 0, .2);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    margin: 1.5rem;
    padding: 3rem 2rem;
   text-align: center;
}

.box-caracteristicas .iconos-cosas{

    font-size: 60px;
    margin: 20px;
    color: pink;
}

.contenido-caracteristicas .box-container-caracterisiticas .box-caracteristicas h3{

    font-size: 2rem;
    color: #333;
    padding-top: 1rem;
}

.contenido-caracteristicas .box-container-caracterisiticas .box-caracteristicas p{

    font-size: 1.3rem;
    color: #666;
    padding: 1rem 0;
}

/* ACERCA INICIO */

.acerca{

    background: url(../assets/img/fruta.png);
    background-size: cover;
    background-position: center;

}

.acerca .columna-acerca{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.acerca .columna-acerca .img-acerca{

    flex: 1 1 40rem;
}

.acerca .columna-acerca .img-acerca img{

    width: 100%;

}



.acerca .columna-acerca .contenido-acerca{

    flex: 1 1 40rem;
}

.acerca .columna-acerca .contenido-acerca h3{

    font-size: 3rem;
    color: #666;

}

.acerca .columna-acerca .contenido-acerca p{

    font-size: 1.5rem;
    color: #666;
    padding: 1rem 0;
}

.acerca .columna-acerca .contenido-acerca .botones-acerca a:last-child{
    margin-left: 2rem;
}


/* review  INICIO*/

.comentarios .box-container-caracterisiticas{

    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.comentarios .box-container-caracterisiticas .box-caracteristicas{

    background: #fff;
    margin: 1rem;
    padding: 1rem;
    text-align: center;
    position: relative;
    border: .1rem solid rgba(0, 0, 0, .2);
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
    flex: 1 1 30rem;
    border-radius: .5rem;
}

.comentarios .box-container-caracterisiticas .box-caracteristicas .fa-quote-right{

    position: absolute;
    top: 1rem;
    right: 2rem;
    font-size: 8rem;
    color: var(--verde);
    opacity: .3;
}

.comentarios .box-container-caracterisiticas .box-caracteristicas .usuario-review img{

    border-radius: 50%;
    object-fit: cover;
    height: 7rem;
    width: 7rem;
    margin-top: 2rem;
}

.comentarios .box-container-caracterisiticas .box-caracteristicas .usuario-review h3{

    color: var(--verde);
    font-size: 2rem;
}

.comentarios .box-container-caracterisiticas .box-caracteristicas .usuario-review .estrellas{

    color: var(--rosa);
    font-size: 1.5rem;
    padding: 1rem 0;
}

.comentarios .box-container-caracterisiticas .box-caracteristicas .comentarios-review{

    color: #666;
    font-size: 1.4rem;
    padding: 1rem;
}

.pie-inicio{

    padding-top: 3rem;
    background: url(../assets/img/footer-bg.png) no-repeat;
    background-size: cover;
    background-position: center;
}

.pie-inicio .box-container-caracterisiticas{

    display: flex;
    flex-wrap: wrap;
}

.pie-inicio .box-container-caracterisiticas .box-caracteristicas{

    flex:  1 1 25rem;
    margin: 2rem;

}

.pie-inicio .box-container-caracterisiticas .box-caracteristicas h3{

    font-size: 2.5rem;
    padding: 1rem 0;
    color: #2A0944;
    text-decoration: underline;
    text-underline-offset: 1rem;
    
}

.pie-inicio .box-container-caracterisiticas .box-caracteristicas p{

    font-size: 1.5rem;
    padding: .3rem 0;
    color: #000;

}

.pie-inicio .box-container-caracterisiticas .box-caracteristicas a{

    display: block;
    font-size: 1.5rem;
    padding: .3rem 0;
    color: #000;

}

.pie-inicio .box-container-caracterisiticas .box-caracteristicas a:hover{

    text-decoration: underline;

}

.pie-inicio .box-container-caracterisiticas .box-caracteristicas .info-contacto{

    display: flex;
    align-items: center;
}

.pie-inicio .box-container-caracterisiticas .box-caracteristicas .info-contacto i{

   margin: .5rem 0;
   margin-right: 1rem;
   border-radius: 50%;
   background: #fff;
   color: var(--rosa);
   font-size: 1.5rem;
   height: 4.5rem;
   width: 4.5rem;
   line-height: 4.5rem;
   text-align: center;
}

.pie-inicio .reservado{

    font-size: 2rem;
    font-weight: bold;
    letter-spacing: .1rem;
    color: #000;
    border-top: .1rem solid #fff5;
    padding: 2.5rem 1rem;
    text-align: center;
}











/* media queries */

@media (max-width:1200px){

    html{
        font-size: 55%;
       
    }

}

@media (max-width:991px){

    section{

        padding: 0 3%;
        padding-top: 7.5rem;
    }    

}


@media (max-width:768px){
   
    header label{
        visibility: visible;
        opacity: 1;
    }
    
    header .navbar-inicio{

        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        background: #fff;
        padding: 1rem 2rem;
        border-top: .1rem solid rgba(0, 0, 0, .2);
        box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .1);
        transform-origin: top;
        transform: scaleY(0);
        opacity: 0;
        transition: .2s linear;
    }

    header .navbar-inicio a{

        display: block;
        margin: 2rem 0;
        font-size: 2rem;
    }

    header input:checked ~ .navbar-inicio{

        transform: scaleY(1);
        opacity: 1;
        
    }

    header input:checked ~ label::before{

        content: "\f00d";
    }


    .home{
        flex-flow: column-reverse;
    }

    .home .imagen-home img{
        width: 100%;
    }

    .home .contenido-home h3{

        font-size: 3.6rem;
    }

    .home .contenido-home p{

        font-size: 1.5rem;
    }

    .acerca{
        background-position: right;
    }


}

@media (max-width:450px){

    html{
        font-size: 50%;
       
    }

    .acerca .columna-acerca .contenido-acerca .botones-acerca a {

        width: 100%;
        
    }
    

    .acerca .columna-acerca .contenido-acerca .botones-acerca a:last-child{
        margin: 1rem 0;
    }
}