@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;700&display=swap');



/* inicia seccion home */
.seccion-servi{

    min-height: 100vh;
    padding: 0% 9%;
    padding-top: 7.5rem;
    padding-bottom: 3rem;
    
    }
    
    .home-servi{
    
        display: flex;
        align-items: center;
        background: url(../assets/img/sr.jpeg) no-repeat;
        background-size: cover;
        background-position: center;
    }
    
    .home-servi .contenido-servi{
    
        padding-left: 1rem;
    }
    
    .home-servi .contenido-servi .hola-servi{
    
        font-size: 2rem;
        color: #444;
    }
    
    
    .home-servi .contenido-servi .nombre-servi{
    
        font-size: 5.5rem;
        color: #f2a7bb;
        font-weight: bolder;
        text-transform: uppercase;
    }
    
    .home-servi .contenido-servi .nombre-servi span{
    
       color: var(--verde);
       font-weight: bolder;
        text-transform: uppercase;
        
    }
    
    .home-servi .contenido-servi p{
    
        max-width: 50rem;
        font-size: 1.5rem;
        color: #000;
        padding-bottom: 1rem;
    }


    /* media query */

@media (max-width:991px){

    .seccion-servi{

        padding: 5% 3%;
        padding-top: 7.5rem;
        padding-bottom: 3rem;
        
        }
}


@media (max-width:768px){

    .home-servi{

        background-position: left;
    }

    .home-servi .contenido-servi .nombre-servi{

        font-size: 3.5rem;
    }
}


@media (max-width:450px){

    .encabezado{
        font-size: 4.5rem;
    }
}