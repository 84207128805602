/* colores
#f2a7bb
#78bf8a */



*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-size: 16px;
}

body{
background-color: #fff;
}

.registro h1{

    text-align: center;
    color: #f2a7bb;
    margin: 80px;
}

.registro h1:hover{

    color: #FFCCD2;
}

.container{
 align-items: center;

}

.container .row .col{
    padding: 20px;
}

input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: #f2a7bb;
    
}

  input:focus {
    border-color: #78bf8a;
  }


.container .row{
    margin-bottom: 40px;
}

.resultados{
    color: #78bf8a;
}

.resultados:hover{
    color: #B1E693;
}

hr{
background:#FF5DA2;
margin-bottom: 30px;
}

.btn{
    background-color: #78bf8a;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;
    border-radius: 3px;
    
  }

  .btn:hover {
      opacity: 1
    }

    .btn{
        margin: 30px;
        
       
    }

    .btn2{
        background-color: #f2a7bb;
        border: none;
        color: white;
        padding: 16px 32px;
        text-align: center;
        font-size: 16px;
        margin: 4px 2px;
        opacity: 0.6;
        transition: 0.3s;
        display: inline-block;
        text-decoration: none;
        cursor: pointer;
        border-radius: 3px;
        
      }
    
      .btn2:hover {
          opacity: 1
        }
    
        .btn2{
            margin: 30px;
            
           
        }

    